.liste-med-cards {

    // RESET
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    //TITLE
    .block-title {
        font-family: $font__main;
        font-weight: $font__weight--bold;
        @include font-size(32, 40);
        @include breakpoint(tablet) {
            @include font-size(40, 48);
        }
        
        text-transform: uppercase;
        color: $color__red;
    }

    .card__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 50%);
            display: grid;
        }

        gap: 2rem;
        padding-top: 2rem;
        max-width: 100%;

        .card__item {
            grid-column: span 1;
            min-height: 400px;
            .cta-func {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            &.half {
                min-height: 350px;
            }
            @media only screen and (min-width: 768px) {
                grid-column: span 2;
            }

            position: relative;

            .card {
                width: 100%;
                height: 100%;
                border-radius: 1.5rem;
                padding: 2.25rem;
                display: grid;
                grid-template-columns: 1fr;
                gap: 2.25rem;

                @media only screen and (min-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }

                .card__text--container {
                    grid-column: 1;
                    grid-row: 1;
                    .card__header {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;
                    }
                    &.bg__blue {
                        color: $color__white;
            
                    }
                    &.bg__red {
                        color: $color__white;
                    }
                    &.bg__grey--new {
                        color: $color__grey--new-dark;
                        .card__label {
                            color: $color__red
                        }
                    }
                    .card__label {
                        text-transform: uppercase;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 26px;
                    }
                    .card__title {
                        text-transform: uppercase;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 32px;
                        @media only screen and (min-width: 768px) {
                            font-size: 32px;
                            line-height: 35px;
                        }


                    }
                    .card__headline {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        *:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .card__cta--container {
                    grid-column: 1;
                    grid-row: 3;
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: center;
                    align-content: flex-start;
                    gap: 1.75rem;
                    z-index: 2;
                    
                    @media only screen and (min-width: 768px) {
                        grid-row: 2;
                        flex-wrap: wrap-reverse;
                        justify-content: flex-start;
                        align-items: flex-end;
                        flex-direction: row;
                        align-items: center;
                    }

                    &>* {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        text-decoration: none;
                        // white-space: nowrap;

                    }

                    .card__cta {
                        order: -1;
                        display: block;
                        height: fit-content;
                        padding: 1rem 2rem;
                        border-radius: 10rem;
                        color: $color__white;
                        width: fit-content;
                        transition: all .22s ease-in-out;
                        cursor: pointer;
                        &.bg__red {
                            background-color: $color__red;

                            &:hover {
                                color: $color__red;
                                background-color: $color__white;
                            }
                        }

                        &.bg__grey {
                            background-color: $color__grey--new-dark;

                            &:hover {
                                color: $color__grey--new-dark;
                                background-color: $color__white;
                            }
                        }
                    }

                    .card__link {
                        display: block;
                        height: fit-content;
                        text-decoration: none;
                        color: $color__white;
                        &:hover {
                            color: $color__grey--new-dark;
                        }
                      
                        &.bg__grey--new {
                            color: $color__grey--new-dark;
                            &:hover {
                                color: $color__red;
                            }
                        }
                    }
                }

                .card__icon--container {
                    grid-column: 1;
                    grid-row: 2 / span 1;
                    position: relative;
                    height: 170px;
                    max-height: 100%;
                    @media only screen and (min-width: 768px) {
                        grid-column: 2;
                        grid-row: 1 / span 2;
                        height: 330px;
                    }

                    display: flex;
                    justify-content: center;
                }

                // grid-template-columns: repeat(1, 1fr);
                // @media only screen and (min-width: 768px) {
                //     grid-template-columns: repeat(2, 1fr);
                // }
                // .card__text--container {
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: space-between;
                //     height: 100%;
                //     .card__cta--container {
                //         background-color: yellow;
                //         .card__cta {
                //             background-color: red;
                //         }
                //         .card__link {
                //             background-color: blue;
                //         }
                //     }
                // }
                // .card__icon--container {
                //     display: flex;
                //     justify-content: center;
                // }
                // height: 100%;
                &:not(.half).icon--left {
                    .card__icon--container {
                        @media only screen and (min-width: 768px) {
                            grid-column: 1;
                        }
                    }
                    .card__text--container {
                        @media only screen and (min-width: 768px) {
                            grid-column: 2;
                        }
                    }
                    .card__cta--container {
                        @media only screen and (min-width: 768px) {
                            grid-column: 2;
                        }
                    }
                }
            }

            &.half {
                grid-column: span 1;

                .card {
                    grid-template-columns: repeat(1, 1fr);
                }

            }
        }
    }

}