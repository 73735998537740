body {
  //background-size: 8px 8px;
  //background-image: linear-gradient(to top, rgba(red, 0.25) 1px, transparent 1px);
}

/*------------------------------------*\
  #SETTINGS
\*------------------------------------*/
@import "settings/fonts";
@import "settings/colors";
/*------------------------------------*\
  #TOOLS
\*------------------------------------*/
@import "tools/mixins";
/*------------------------------------*\
  #GENERIC
\*------------------------------------*/
@import "generic/normalize";
/*------------------------------------*\
  #SHARED
\*------------------------------------*/
@import "shared/color-classes";
@import "shared/typographies";
@import "shared/headings";
@import "shared/links";
@import "shared/lists";
@import "shared/social-links";
@import "shared/cards";
@import "shared/info-box";
@import "shared/helpers";
@import "shared/media";
@import "shared/tables";
/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/
@import "layout/grid";
@import "layout/content";
@import "layout/angles";
@import "layout/blocks";
@import "layout/landing-page";

/*------------------------------------*\
  #NAVIGATION
\*------------------------------------*/
@import "navigation/main-menu";
@import "navigation/top-menu";
@import "navigation/footer-menu";
@import "navigation/pager";
/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
@import "components/header";
@import "components/footer";
@import "components/breadcrumbs";
@import "components/newsletter-signup";
@import "components/overlay-modal";

/*------------------------------------*\
  #CONTENT TYPES
\*------------------------------------*/
@import "content-types/raad-og-vejledning";
@import "content-types/findtr";
@import "content-types/campaign";
/*------------------------------------*\
  #VENDOR
\*------------------------------------*/
@import "vendor/owl/owl.carousel";
@import "vendor/owl/owl.theme.default";
/*------------------------------------*\
  #CONTENT ELEMENTS
\*------------------------------------*/
@import "content-elements/link-lists";
@import "content-elements/accordion";
@import "content-elements/quotes";
@import "content-elements/fact-boxes";
@import "content-elements/bylines";
@import "content-elements/events";
@import "content-elements/news";
@import "content-elements/featured-articles";
@import "content-elements/publications";
@import "content-elements/data";
@import "content-elements/shortcuts";
@import "content-elements/people";
@import "content-elements/related";
@import "content-elements/related-publications";
@import "content-elements/tabs";
@import "content-elements/frontpage-header";
@import "content-elements/faggrupper";
@import "content-elements/meetings";
@import "content-elements/campaign-banner";
@import "content-elements/facet-filters";
@import "content-elements/snapengage";
@import "content-elements/liste-med-cards";
