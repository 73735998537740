.mobile-menu-toggle {
	display: block;
	position: absolute;
	top: 40px;
	transform: translate3d(0,-50%,0);
	right: 16px;
	width: 26px;
	height: 24px;
	text-decoration: none;
	font-size: 36px;
	z-index: 3;

	&.open {
		position: fixed;
	}
	@include breakpoint(tablet) {
		display: none;
	}
	// MENU TOGGLE BUTTON
	// =================
	&__lines {
		content: " ";
		background-color: $color__black;
		height: 3px;
		width: 100%;
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate3d(0,-50%,0);
		transition: 110ms ease-in-out;

		&:after,
		&:before {
			content: " ";
			background-color: $color__black;
			height: 3px;
			width: 100%;
			display: inline-block;
			position: absolute;
			left: 0;
			transition: 110ms ease-in-out;
		}

		&:before {
			transform: translate3d(0,-10px,0);
		}

		&:after {
			transform: translate3d(0,10px,0);
		}

		&.open {
			background: transparent;

			&:before {
				transform: rotate(45deg);
				transition-delay: 0.1s;
			}

			&:after {
				transform: rotate(-45deg);
				transition-delay: 0.2s;
			}
		}
	}
	// CIRCLE REVEAL
	// =================
	&__menubg {
		display: block;
		position: fixed;
		background: $color__white;
		width: 200%;
		height: 100%;
		top: -100%;
		right: -100%;
		z-index: 2;
		transform: scale(0) rotate(45deg);
		transition: 0.5s;
		@include breakpoint(tablet) {
			display: none;
		}

		&.open {
			display: block;
			transform: scale(30) rotate(45deg);
			transition: 1s;
		}
	}
	@include breakpoint(tablet) {
		display: none;
	}
}

.mobile-menu-wrapper {
	display: inline;

	&.open {
		display: block;
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		padding: 100px 20px;
		z-index: 2;

		.main-menu {
			display: inline-block;
			position: relative;
			width: 100%;
			margin-bottom: 20px;

			ul {
				li {
					animation-name: fadeInRight;
					animation-delay: 0.22s;
					animation-fill-mode: both;
					$delaybase: 0.22s;
					animation-duration: $delaybase;
					@for $i from 1 through 12 {
						&:nth-of-type(#{$i}) {
							animation-delay: (#{$i*0.1s + $delaybase});
						}
					}
				}
			}
		}

		.top-menu {
			position: relative;
			display: inline-block;
			animation-name: fadeIn;
			animation-duration: 0.22s;
			animation-delay: 1s;
			animation-fill-mode: both;
		}
	}
	@include breakpoint(tablet) {
		display: inline;
	}
}

.top-menu {
	display: none;
	@include breakpoint(tablet) {
		display: block;
	}
}

.main-menu {
	display: none;
	@include breakpoint(tablet) {
		display: block;
		animation-duration: 0s;
	}
	@include breakpoint(desktop) {
		display: inline-block;
		max-width: 620px;
		float: right;
		margin-top: 88px;
		position: absolute;
    	top: 0;
    	right: 0;
	}

	&__title {
		display: none;
	}

	&__list {
		padding: 0;
		@include breakpoint(tablet) {
			transform: translateX(-32px);
			display: flex;
			flex-wrap: wrap;
			display: -webkit-flex;
			-webkit-flex-wrap: wrap;
			margin: 0 0 50px;
		}
		@include breakpoint(desktop) {
			transform: translateX(0);
		}
	}

	&__item {
		list-style: none;
		display: block;
		margin: 0 0 30px;
		@include breakpoint(tablet) {
			display: inline-block;
			width: 20%;
			margin: 0;
			padding-left: 56px;

			&:first-child {
				padding-left: 56px;
			}
		}
		@include breakpoint(desktop) {
			margin: 0 0 30px;
			width: 25%;
		}

		a {
			font-weight: $font__weight--bold;
			@include font-size(22, $line-height);
			text-decoration: none;
			text-transform: uppercase;
			transition: all 120ms ease-in-out;
			position: relative;
			display: inline-block;
			@include breakpoint(tablet) {
				 @include font-size(18, $line-height);
				@at-root .menu-color--white & {
					&:link,
					&:visited {
						color: $color__white;
						cursor: pointer;
					}
				}
			}

			&:before {
				content: " ";
				height: 2/18 * 1em;
				width: 25/18 * 1em;
				display: inline-block;
				position: absolute;
				background-color: $color__grey;
				top: 22/18 * 1em;
				left: -(16/18 * 1em);
				transform-origin: 0 0;
				transform: rotate(-65deg);
				transition: all 120ms ease-in-out;
				@include breakpoint(tablet) {
					 @at-root .menu-color--white &{
						background-color: $color__white;
					}
				}
			}

			&:link {
				color: $color__text;
			}
			/* visited link */
			&:visited {
				color: $color__text;
			}
			/* mouse over link */
			&:hover {
				color: $color__blue;
				@at-root {
					.bg-color__blue &,
					.bg-color__green &,
					.bg-color__orange &,
					.bg-color__pink &,
					.bg-color__red &,
					body.home & {
						color: $color__white;

						&:before {
							background-color: $color__white;
						}
					}

					body.home .menu-color--white & {
						color: $color__text;

						&:before {
							background-color: $color__text;
						}
					}
				}

				&:before {
					background-color: $color__blue;
				}
			}
			/* selected link */
			&:active {
				color: $color__blue;
			}
		}

		&--active {
			@at-root {
				.bg-color__blue &,
				.bg-color__green &,
				.bg-color__orange &,
				.bg-color__pink &,
				.bg-color__red &,
				body.home & {
					a {
						&:link {
							color: $color__blue;
							@include breakpoint(tablet) {
								color: $color__white;
							}
						}
						/* visited link */
						&:visited {
							color: $color__blue;
							@include breakpoint(tablet) {
								color: $color__white;
							}
						}

						&:before {
							background-color: $color__blue;
							@include breakpoint(tablet) {
								background-color: $color__white;
							}
						}
					}
				}
			}
		}
	}
}
