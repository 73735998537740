.tabs {
	margin-top: 3.5rem;
	margin-bottom: 2rem;

	&--events {
		margin-top: 0;
	}

	&__nav {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		border-bottom: 1px solid $color__grey--lighter;
		@include clearfix();
		margin: 0 -1rem;
		@include breakpoint(tablet) {
			margin: 0;
		}
	}

	&__list {
		//display: none;
		padding: 0;
		margin: 0;
		@include clearfix();
		display: flex;
		align-items: stretch;
		@include breakpoint(tablet) {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: stretch;
		}
	}

	&__list-item {
		list-style: none;
		flex-grow: 1;
		//width: 20%;
		text-align: center;
		padding: 20px 1rem;
		@include font-size(16, 24);
		font-weight: 700;
		color: rgba($color__grey, 0.50);
		text-decoration: none;
		transition: all 180ms ease-in-out;
		position: relative;
		margin: 0;
		width: auto;
		display: table;
		//float: left;
		cursor: pointer;
		background-color: $color__white;
		box-shadow: inset 0 0 0 0 $color__blue;
		@include breakpoint(tablet) {}
		@include breakpoint(desktop) {}

		&:hover {
			color: $color__blue;
		}

		&:focus {
			outline: none;
			color: $color__blue;
			background-color: $color__white;
		}

		&--active,
		&[aria-selected='true'] {
			outline: none;
			color: $color__blue;
			background-color: $color__white;
			box-shadow: inset 0 -4px 0 0 $color__blue;
		}
	}

	&__list-item-link-text {}

	&__content {
		padding-top: 40px;
	}

	&__pane {
		@include breakpoint(tablet) {}
		@include breakpoint(desktop) {}

		&[aria-hidden='true'] {
			display: none;
			/*
			@include breakpoint(tablet) {
				display: none;
			}*/
		}
	}
}
