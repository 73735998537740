.content {
  &__header {
    display: inline-block;
    width: 100%;

    &--landing-page {
      z-index: -1;
      background-repeat: no-repeat;
      background-size: 100%;
      padding-bottom: 56%;
      background-color: $color__orange;
      background-blend-mode: luminosity;
      margin-top: -81px;
      position: relative;
      display: block;

      @include breakpoint(tablet) {
        margin-top: -200px;
      }

      &--alt {
        background-color: transparent;
        background-position: center;
        padding-bottom: 40%;
      }

    }

    

  }
}

.latest {
  padding-bottom: 48px;
}