/* Base colors */
$color__red: #ea5053;
$color__red--campaign: #ec7477;
$color__yellow: #f0e746;
$color__pink: #ed6ea7;
$color__peach: #f9cacb;
$color__green: #94c356;
$color__blue: #88cdd3;
$color__orange: #f7a622;
$color__white: #ffffff;
$color__black: #000000;
$color__grey--new: #eeeeee;
$color__grey--new-dark: #555555;
$color__grey--lighter: #e8e8e8;
$color__grey--light: #d9d9d9;
$color__grey: #414042;
$color__grey--dark: #221f20;
$color__grey--footer: #343334;
/* Text colors */
$color__text: $color__grey;
