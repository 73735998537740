.accordion {
  border-top: 1px solid $color__grey--light;
  margin-bottom: 1.75rem;

  &__item {
    border-bottom: 1px solid $color__grey--light;
  }

  &__headline {
    padding: 1em 72px 1em 0;
    margin: 0;
    position: relative;

    .plus {
      position: absolute;
      width: 48/28 * 1em;
      height: 48/28 * 1em;
      border: 2px solid $color__red;
      border-radius: 24/28 * 1em;
      display: inline-block;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 220ms ease-in-out;

      &:after,
      &:before {
        transition: all 220ms ease-in-out;
        -webkit-font-smoothing: subpixel-antialiased;
      }

      &:before {
        width: 12px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        content: " ";
        display: inline-block;
        background-color: $color__red;
      }

      &:after {
        width: 2px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        content: " ";
        display: inline-block;
        background-color: $color__red;
      }
    }

    &:hover {
      cursor: pointer;

      .plus {
        border: 2px solid $color__blue;

        &:after,
        &:before {
          background-color: $color__blue;
        }
      }
    }

    &.is-open {
      .plus {
        border: 2px solid $color__blue;

        &:after,
        &:before {
          background-color: $color__blue;
        }

        &:after {
          height: 0;
          top: 50%;
        }
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: all 220ms ease-in-out;

    &.is-open {
      padding-bottom: 20px;
      max-height: 20000px;
    }

    h3 {
      @include font-size(16, 28);
      font-weight: $font__weight--regular;
      margin: 0;
      padding-bottom: 20px;
      @include breakpoint(tablet) {
         @include font-size(18, 28);
      }
    }
  }
}
