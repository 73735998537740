.facetwp-pager {
	display: flex;
	margin-bottom: 40px;
	margin-top: 40px;
}

.facetwp-page {
	text-decoration: none;
	font-weight: $font__weight__bold;

	&.active {
		font-weight: $font__weight__bold;
		color: $color__blue;
	}

	&:hover {
		color: $color__red;
	}
}

.pager {
	margin-bottom: 40px;

	ul {
		display: flex;
		padding: 0;
	}

	li {
		list-style: none;
		margin-right: 8px;

		&.active a {
			font-weight: $font__weight__bold;
			color: $color__blue;
		}
	}

	a {
		text-decoration: none;
		font-weight: $font__weight__bold;

		&:hover {
			color: $color__red;
		}
	}
}
