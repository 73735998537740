// Rem output with px fallback
/*@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1rem;
}
*/
@mixin font-size($sizeValue: 16, $line: $sizeValue * $line-height--calculated) {
	font-size: ($sizeValue) + px;
	line-height: ($line) + px;
	font-size: ($sizeValue / 16) + rem;
	line-height: ($line / 16) + rem;
}
// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
// Clearfix
@mixin clearfix() {
	&:after,
	&:before {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/*********************
BREAKPOINTS
*********************/
@mixin breakpoint($point) {
	 @if $point==desktop-lg{
		@media (min-width: 1160px) {
			 @content;
		}
	}
	@else if $point==desktop {
		 @media (min-width: 1024px){
			@content;
		}
	}
	@else if $point==tablet {
		 @media (min-width: 768px){
			@content;
		}
	}
	@else if $point==mobileonly {
		 @media (max-width: 320px){
			@content;
		}
	}
}
// mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
	// Possible values for $pseudo are: before, after, both
	@if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
		position: relative;
		z-index: 1;
		$selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

		#{$selector} {
			background: inherit;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			z-index: -1;
			-webkit-backface-visibility: hidden; // for Chrome Windows
		}

		@if $pseudo == 'before' {
			#{$selector} {
				top: 0;
				@if $flip {
					transform: skewY($angle * -1);
					transform-origin: 0 0;
				}
				@else {
					transform: skewY($angle);
					transform-origin: 100% 0;
				}
			}
		}

		@if $pseudo == 'after' {
			#{$selector} {
				bottom: 0;
				@if $flip {
					transform: skewY($angle);
					transform-origin: 0 100%;
				}
				@else {
					transform: skewY($angle * -1);
					transform-origin: 100%;
				}
			}
		}

		@if $pseudo == 'both' {
			&:before {
				top: 0;
				@if $flip {
					transform: skewY($angle * -1);
					transform-origin: 0 0;
				}
				@else {
					transform: skewY($angle);
					transform-origin: 100% 0;
				}
			}

			&:after {
				bottom: 0;
				@if $flip {
					transform: skewY($angle);
					transform-origin: 0 0;
				}
				@else {
					transform: skewY($angle * -1);
					transform-origin: 100%;
				}
			}
		}
	}
}
