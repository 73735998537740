.link-list {
	margin-bottom: 60px;

	&__headline {
		@include font-size(18,24);
		font-weight: $font__weight--light;
		text-transform: uppercase;
		margin-bottom: 20px;
		@include breakpoint(tablet) {
			 @include font-size(24,32);
		}
		@at-root {
			.bg-color__blue & {
				color: $color__white;
			}
		}
	}

	&__list {
		padding: 0;
		//border-top: 1px solid rgba(255,255,255,0.4);
		@at-root .no-fact-box & {
			 @include breakpoint(tablet){
				display: flex;
				-webkit-flex: flex;
				align-items: stretch;
				margin: 0 -1rem;
				flex-wrap: wrap;
				display: -webkit-flex;
				-webkit-flex-wrap: wrap;
			}
		}
	}

	&__item {
		list-style: none;
		//border-bottom: 1px solid rgba(255,255,255,0.4);
		@at-root .no-fact-box & {
			 @include breakpoint(tablet){
				width: 50%;
				padding: 0 1rem;
				margin: -1px 0;

				&:nth-child(1),
				&:nth-child(2) {
					a {
						border-top: 1px solid #f4f4f4;
					}
				}
			}
		}
	}

	&__link {
		text-decoration: none;
		@include font-size(18, 24);
		font-weight: $font__weight--bold;
		display: block;
		padding: 10px 32px 10px 0;
		transition: all 220ms ease-in-out;
		position: relative;
		color: $color__red;
		border-top: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
		margin: -1px 0;
		@at-root .no-fact-box & {
			 @include breakpoint(tablet){
				display: block;
				height: 100%;
				border-top: none;
			}
		}
		@include breakpoint(desktop) {
			 @include font-size(24, 32);
		}

		&:link,
		&:visited {
			color: $color__red;
		}

		&:after {
			content: "→";
			color: $color__red;
			text-indent: 0;
			text-align: center;
			position: absolute;
			top: 10px;
			right: 0;
			bottom: 0;
			padding: 0;
			//transform: translateY(-50%);
			font-weight: $font__weight--light;
			transition: all 220ms ease-in-out;
		}

		&:hover {
			color: $color__blue;

			&:after {
				color: $color__blue;
			}
		}
		@at-root {
			.bg-color__blue & {
				color: $color__red;

				&:hover {
					color: $color__white;

					&:after {
						color: $color__white;
					}
				}
			}

			.bg-color__red & {
				color: $color__text;

				&:after {
					color: $color__text;
				}

				&:hover {
					color: $color__white;

					&:after {
						color: $color__white;
					}
				}
			}
		}
	}
}

.siblings {
	padding-top: 48px;
	@include breakpoint(tablet) {
		padding-top: 88px;
	}
}
