.fact-box {
	@at-root .raad-og-vejledning__header & {
		 @include breakpoint(desktop){
			padding-right: 88px;
		}
	}

	&__headline {
		@include font-size(16,24);
		font-weight: $font__weight--medium;
		text-transform: uppercase;
		margin-bottom: 0.66em;
		@include breakpoint(desktop) {
			 @include font-size(24,24);
		}
	}

	&__content {
		@include font-size(18,24);
		font-weight: $font__weight--bold;
		@include breakpoint(desktop) {
			 @include font-size(28,34);
		}
		// Color rules
		@at-root {
			.bg-color__blue &,
			.bg-color__green &,
			.bg-color__red & {
				color: $color__white;
			}
		}

		a {
			text-decoration: none;
			color: $color__red;

			&:hover {
				color: $color__blue;
			}
			@at-root .bg-color__blue & {
				color: $color__red;

				&:hover {
					color: $color__white;
				}
			}
		}
	}
}
