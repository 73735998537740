h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font__main;
}

h1 {
	@include font-size(26, 32);
	font-weight: 800;
	margin: 8px 0 20px;
	@include breakpoint(tablet) {
		 @include font-size(48, 56);
		margin: 40px 0 28px;
	}
	@include breakpoint(desktop) {
		 @include font-size(64, 76);
		margin: 40px 0 28px;
		@at-root .single-hoeringssvar & {
			 @include font-size(48, 56);
		}
	}
}

h2 {
	@include font-size(18, 22);
	font-weight: $font__weight--light;
	margin: 32px 0 8px;
	@include breakpoint(tablet) {
		 @include font-size(24, 28);
		margin: 56px 0 12px;
	}

	&.no-top-margin {
		margin-top: 0;
	}

	main .col > &:first-child {
    	margin-top: 1rem;
	}
}

h3 {
	@include font-size(16, 28);
	font-weight: $font__weight--medium;
	margin: 0;
	@include breakpoint(tablet) {
		 @include font-size(18, 28);
	}
}

h4,
h5,
h6 {
	@include font-size(16, 28);
	font-weight: $font__weight--regular;
	margin: 0;
}

.headline {
	&--small {
		@include breakpoint(tablet) {
			 @include font-size(40, 44);
		}
	}

	&--column {
		@include font-size(18, 22);
		font-weight: $font__weight--medium;
		margin-bottom: 12px;
		margin-top: 0;
		@include breakpoint(tablet) {
			 @include font-size(24, 28);
		}
		@at-root {
			.bg-color__blue & {
				color: $color__white;
			}
		}
	}

	&--block {
		@include font-size(12, 14);
		font-weight: inherit;
		margin-bottom: 1rem;
		//margin-top: 0;
		text-transform: uppercase;
		@include breakpoint(tablet) {
			 @include font-size(24, 28);
			margin-bottom: 32px;
			margin-top: 0;
		}
		@at-root {
			.bg-color__blue & {
				//	color: $color__white;
			}
		}
		@at-root {
			.angled-top & {}
		}
	}

	&--no-margin {
		margin-bottom: 0;
	}
}

.subheader {
	font-weight: $font__weight--regular;
}
