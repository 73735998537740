.related {
	&__headline {
		margin-bottom: 40px;
		text-transform: uppercase;
	}

	&__list {
		padding: 0;
		margin: 0;
	}

	&__item {
		list-style: none;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin-bottom: 24px;
	}

	&__image {
		max-width: 200px;
		margin: 0;
	}

	&__description {
		@include breakpoint(tablet) {
			padding-left: 24px;
		}
	}

	&__title {
		@include font-size(28,28);
		color: $color__blue;

		a {
			text-decoration: none;
		}
	}
}

.related-content {
	padding: 40px 0 0;
	@include breakpoint(tablet) {
		max-width: 62.5%;
	}

	&__headline {
		@include font-size(12,14);
		text-transform: uppercase;
		font-weight: $font__weight--light;
		margin: 0 0 16px;
		@include breakpoint(tablet) {
			 @include font-size(24,28);
			margin: 0 0 32px;
		}
	}

	&__list {}

	&__item {
		margin-bottom: 40px;
	}

	&__type {
		margin: 0;
		@include font-size(12,14);
		text-transform: uppercase;
		color: $color__blue;
		@include breakpoint(tablet) {
			 @include font-size(14,24);
		}
	}

	&__manchet {
		margin: 0 0 2px;
		@include font-size(14,24);
		@include breakpoint(tablet) {
			 @include font-size(16, 24);
		}
	}

	&__date {
		@extend .news-list__date;
	}

	&__title {
		@include font-size(18,24);
		margin-bottom: 8px;
		@include breakpoint(tablet) {
			 @include font-size(24, 32);
		}

		a {
			color: $color__red;
			text-decoration: none;
			transition: all 220ms ease-in-out;

			&:hover {
				color: $color__black;
			}
		}
	}
}
