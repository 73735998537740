.frontpage-header {
	position: relative;
	z-index: 1;
	height: 320px;
	background-color: $color__blue;
	margin-bottom: 48px;
	margin-top: 72px;
	@include breakpoint(tablet) {
		height: 600px;
		margin-top: 0;
	}

	&__left {
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		right: 50%;
		background-size: cover;
		background-position: center left;
		overflow: hidden;
		display: none;
		@include breakpoint(desktop-lg) {
			display: inline-block;
		}
	}

	&__right {
		padding-top: 200px;
		overflow: hidden;
		@include breakpoint(tablet) {
			padding-top: 300px;
		}
		@include breakpoint(desktop) {
			padding-top: 200px;
		}
		@include breakpoint(desktop-lg) {
			position: absolute;
			left: calc(50% - 280px);
			bottom: 0;
			top: 0;
			right: -150px;
			border-left: 100px solid $color__white;
			transform: skew(-25deg);
		}
	}

	&__content {
		z-index: 2;
		position: relative;
		background-size: cover;
		background-position: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include breakpoint(desktop-lg) {
			background-position: center right;
			transform: skew(25deg);
			margin-left: -150px;
		}
	}

	&__text {
		position: absolute;
		top: 32px;
		left: 1rem;
		right: 1rem;
		@include breakpoint(tablet) {
			left: 3rem;
			right: 3rem;
			top: 232px;
		}
		@include breakpoint(desktop) {
			left: 3rem;
			right: 3rem;
			top: 200px;
		}
		@include breakpoint(desktop-lg) {
			top: 210px;
			right: auto;
			left: 300px;
		}
	}

	&__description {
		@include font-size(18, 18);
		font-weight: 400;
		text-transform: uppercase;
		color: $color__grey--light;
		margin: 0 0 5px;
	}

	a {
		text-decoration: none;
	}

	h1 {
		color: $color__white;
		margin-top: 0;
		@include font-size(24, 32);
		@include breakpoint(tablet) {
			 @include font-size(56, 64);
			max-width: 570px;
		}
		@include breakpoint(desktop) {
			 @include font-size(56, 64);
			max-width: 570px;
		}
	}

	.breaking {
		position: absolute;
		z-index: 3;
		display: block;
		width: 100%;
		bottom: 0;
		padding: 15px 1rem;
		background: $color__orange;
		@include breakpoint(tablet) {
			padding: 15px 3rem;
		}
		@include breakpoint(desktop-lg) {
			padding: 20px 20px 20px 75px;
			transform: skew(25deg);
			left: -25px;
			right: -25px;
			width: auto;
		}

		a {
			color: $color__white;

			h3 {
				@include font-size(12,18);
				font-weight: 600;
				text-transform: none;
				@include breakpoint(tablet) {
					 @include font-size(24,24);

					&:before {
						content: '//';
						margin: 0 10px;
						color: $color__grey;
						font-weight: 400;
					}
				}
			}
		}
	}
	/*
	.white {
		@media screen and (min-width: 1120px) {
			display: block;
			width: 85px;
			height: 2000px;
			background: $color__white;
			transform: rotate(25deg);
			position: absolute;
			top: -350px;
			left: 30px;
			z-index: 1;
			pointer-events: none;
		}
	}

	*/
	.red {
		position: absolute;
		z-index: 1;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
	}

	.blue {
		position: absolute;
		z-index: 1;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
	}

	&.leftred {
		.description {
			color: $color__red;
		}
		@media screen and (min-width: 1120px) {
			.container {
				padding-left: 455px;
			}

			.breaking {
				left: 50%;
				margin-left: -400px;
				z-index: 0;
			}
		}
	}
}
