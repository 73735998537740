.container {
	max-width    : 1220/16 * 1em;
	width        : 100%;
	padding-left : 1rem;
	padding-right: 1rem;
	margin       : 0 auto;
	@include breakpoint(tablet) {
		padding-left : 3rem;
		padding-right: 3rem;
	}
	@at-root .main-body__content & {
		padding-left : 0;
		padding-right: 0;
		@include breakpoint(tablet) {
			padding-left : 0;
			padding-right: 0;
		}
	}
}

.flex {
	display          : flex;
	align-items      : stretch;
	flex-wrap        : wrap;
	display          : -webkit-flex;
	-webkit-flex-wrap: wrap;
}

.row {
	display          : flex;
	flex-wrap        : wrap;
	display          : -webkit-flex;
	-webkit-flex-wrap: wrap;
	align-items      : stretch;
	margin           : 0 -1rem;
	position         : relative;
	z-index          : 1;
	@include breakpoint(tablet) {
		margin: 0 -2rem;
	}
}

.col-layout--Emneside {
	@include breakpoint(tablet) {
		padding-top: 64px;
	}
}

.col {
	//flex-grow: 1;
	padding: 0 1rem;
	@include breakpoint(tablet) {
		padding: 0 2rem;
	}

	&--1 {
		width: 100%;
	}

	&--2 {
		width: 100%;
		@include breakpoint(tablet) {
			width: 50%;
			@at-root .col-layout--75-25 & {
				&:first-child {
					width: 62.5%;
				}

				&:last-child {
					width: 37.5%;
				}
			}
			@at-root .col-layout--25-75 & {
				&:first-child {
					width: 37.5%;
				}

				&:last-child {
					width: 62.5%;
				}
			}
			@at-root .col-layout--Emneside & {
				&:first-child {
					width: 37.5%;
					@include breakpoint(tablet) {
						padding: 0 72px 0 32px;
					}
				}

				&:last-child {
					@include breakpoint(tablet) {
						padding: 0 32px 0 72px;
					}
				}
			}
		}
	}

	&--3 {
		width: 100%;
		@include breakpoint(tablet) {
			width: 33.3333%;
		}
	}

	&--1-4,
	&--4 {
		width: 100%;
		@include breakpoint(tablet) {
			width: 25%;
		}
	}

	&--3-4 {
		width: 100%;
		@include breakpoint(tablet) {
			width: 75%;
		}
	}

	&--left {
		width: 100%;
		@include breakpoint(tablet) {
			width: 62.5%;
		}
		@at-root .main-body__content & {
			width: 100%;
			@include breakpoint(tablet) {
				width: 100%;
			}
		}
	}

	&--right {
		width: 100%;
		@include breakpoint(tablet) {
			width: 37.5%;
		}
	}
}

.row-with-cols {
	&.bg-color__white {
		@include breakpoint(tablet) {
			margin-bottom: 48px;

			&.no-bottom-margin {
				margin-bottom: 0;
			}

			&.extra-bottom-margin {
				margin-bottom: 88px;
			}
		}
	}
}
