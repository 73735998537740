.events-list {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	display: -webkit-flex;
	-webkit-flex-wrap: wrap;
	margin-bottom: 20px;

	&__item {
		width: 100%;
		margin-bottom: 20px;
		@include breakpoint(tablet) {
			width: 50%;
			padding-right: 20px;
			@at-root .events-list--single-col & {
				width: 62.5%;
				padding-right: 0;
			}
		}

		&--full-width {
			width: 100%;
		}
	}
}

.event {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	&__date {
		border: 2px solid $color__blue;
		width: 48px;
		height: 48px;
		border-radius: 24px;
		@include font-size(12, 16);
		color: $color__red;
		font-weight: $font__weight--medium;

		&-day,
		&-month {
			margin: 0 10px;
			text-align: center;
			display: block;
		}

		&-day {
			margin-top: 5px;
		}

		&-month {
			padding-top: 1px;
			border-top: 1px solid $color__red;
		}
	}

	&__details {
		padding-left: 20px;
		flex: 1;
	}

	&__organizer {
		@include font-size(14, 14);
		text-transform: uppercase;
		color: $color__blue;
	}

	&__title {
		@include font-size(18, 24);
		font-weight: $font__weight--medium;
		@include breakpoint(tablet) {
			 @include font-size(24, 32);
		}

		a {
			text-decoration: none;
			color: $color__red;

			&:hover {
				color: $color__black;
			}
		}
	}

	&__info {
		font-weight: $font__weight--medium;
		@include font-size(12,24);
	}

	&__description {
		@include font-size(14,24);
		margin-bottom: 1em;
	}

	&__signup-deadline {
		font-weight: $font__weight--medium;
		@include font-size(12,24);
		display: block;
		width: 100%;
		color: $color__blue;

		&--over {
			font-style: italic;
			color: $color__red;
		}
	}

	&__location {}

	&-info__map {
		width: 100%;
		padding-bottom: 50%;
		margin: 20px 0;
		border: 1px solid $color__grey--light;
		@include breakpoint(tablet) {
			padding-bottom: 80%;
		}

		img {
			max-width: inherit !important;
		}
	}
}
