.byline {
	@include font-size(14, 20);
	@include breakpoint(tablet) {
		 @include font-size(16, 20);
	}

	&__headline {
		font-weight: $font__weight--light;
	}

	&__content {
		font-weight: $font__weight--bold;
	}
}
