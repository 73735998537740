.footer-menu {
	margin-bottom: $line-height--calculated;
	display: block;
	@include breakpoint(tablet) {
		display: inline-block;
	}

	&--bottom {
		width: 100%;
		@include breakpoint(tablet) {
			width: 33.3333%;
			padding-right: 20px;
		}
	}

	&--small {
		display: block;
		margin-top: 40px;
		@include breakpoint(tablet) {
			margin-top: 0;
			float: right;
			display: block;
			width: 100%;
			@include clearfix();
		}
	}

	&__list {
		padding: 0;
		margin: 0 0 40px;
		@include breakpoint(tablet) {
			margin: 0;
		}
	}

	&__item {
		list-style: none;
		margin: 6px 0;
		line-height: 1.375em;
		@at-root .footer-menu--horizontal & {
			 @include breakpoint(tablet){
				margin-right: 20/12 * 1em;
				display: inline;

				&:after {
					content: " ";
					width: 2px;
					height: 9/12 * 1em;
					background-color: $color__blue;
					position: relative;
					display: inline-block;
					left: 1em;
				}

				&:last-child {
					margin-right: 0;

					&:after {
						display: none;
					}
				}
			}
		}
		@at-root .footer-menu--small & {
			 @include font-size(12, 16);
			@include breakpoint(tablet) {
				margin-right: 20/12 * 1em;

				&:after {
					content: " ";
					width: 2px;
					height: 9/12 * 1em;
					background-color: $color__white;
					position: relative;
					display: inline-block;
					left: 1em;
				}

				&:last-child {
					margin-right: 0;

					&:after {
						display: none;
					}
				}
			}
		}

		a {
			@include font-size(18, 22);
			font-weight: $font__weight--bold;
			text-decoration: none;
			transition: all 220ms ease-in-out;
			position: relative;
			display: inline-block;

			&:link {
				color: $color__blue;
			}
			/* visited link */
			&:visited {
				color: $color__blue;
			}
			/* mouse over link */
			&:hover {
				color: $color__white;
			}
			/* selected link */
			&:active {
				color: $color__blue;
			}
			@at-root .footer-menu--small & {
				 @include font-size(12, 22);
				font-weight: $font__weight--bold;
				text-decoration: none;
				transition: all 220ms ease-in-out;
				position: relative;

				&:link {
					color: $color__white;
				}
				/* visited link */
				&:visited {
					color: $color__white;
				}
				/* mouse over link */
				&:hover {
					color: $color__blue;
				}
				/* selected link */
				&:active {
					color: $color__white;
				}
			}
		}
	}
}
