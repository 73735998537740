.quotes {
	position: relative;
	padding: 32px 0;
	@include breakpoint(tablet) {
		padding: 72px 0 32px;
	}

	&__headline {
		//@include font-size(18, 24);
		text-transform: uppercase;
	}

	&__item {
		@include font-size(16, 24);
		line-height: 1.333;
		font-weight: $font__weight--bold;
		color: $color__blue;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		@include breakpoint(tablet) {
			 @include font-size(32, 36);
		}
	}

	&__text {
		width: 66.6666%;
	}

	&__image {
		width: 33.3333%;
		padding: 0 8%;

		img {
			border-radius: 50%;
			height: auto;
		}
	}

	p {
		margin: 0;
	}

	cite {
		@include font-size(12, 16);
		font-weight: $font__weight--light;
		color: $color__black;
		font-style: normal;
		@include breakpoint(tablet) {
			 @include font-size(16, 24);
		}
	}

	&__name {
		font-weight: $font__weight--bold;
	}
}
