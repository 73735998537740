.top-menu {
	@extend .container;
	@include breakpoint(tablet) {
		margin: 0;
		width: auto;
		position: absolute;
		top: 20px;
		right: 20px;
	}

	&__list {
		padding: 0;
	}

	&__item {
		list-style: none;
		@include breakpoint(tablet) {
			display: inline;
			margin-left: 32px;
		}

		a {
			@include font-size(14, 28);
			text-decoration: none;
			text-transform: uppercase;
			transition: all 220ms ease-in-out;
			position: relative;
			font-weight: $font__weight--regular;

			&:link,
			&:visited {
				color: $color__text;
			}
			@at-root {
				.menu-color--white & {
					&:link,
					&:visited {
						@include breakpoint(tablet) {
							color: $color__white;
						}
					}
				}
			}
			/* mouse over link */
			&:hover {
				color: $color__blue;
				@at-root {
					.bg-color__blue &,
					.bg-color__green &,
					.bg-color__orange &,
					.bg-color__pink &,
					.bg-color__red &,
					.home & {
						@include breakpoint(tablet) {
							color: $color__white;
						}
					}

					.menu-color--white & {
						color: $color__text;
					}
				}
			}
			/* selected link */
			&:active {
				color: $color__blue;
			}
			@at-root {
				.top-menu__item--active & {
					color: $color__white;
				}
			}
		}

		&--icon-contact {
			a {
				background-image: url("../img/icon__contact--black.svg");
				background-position: right center;
				background-size: contain;
				background-repeat: no-repeat;
				padding-right: 28px;
				@at-root {
					.menu-color--white & {
						@include breakpoint(tablet) {
							background-image: url("../img/icon__contact--white.svg");
						}
					}
				}

				&:hover {
					background-image: url("../img/icon__contact--blue.svg");
					@at-root {
						.bg-color__blue &,
						.bg-color__green &,
						.bg-color__orange &,
						.bg-color__pink &,
						.bg-color__red &,
						.home & {
							background-image: url("../img/icon__contact--white.svg");
						}

						.menu-color--white & {
							background-image: url("../img/icon__contact--black.svg");
						}
					}
				}
			}
		}

		&--icon-login {
			a {
				background-image: url("../img/icon__login--black.svg");
				background-position: right center;
				background-size: contain;
				background-repeat: no-repeat;
				padding-right: 28px;
				@at-root {
					.menu-color--white & {
						@include breakpoint(tablet) {
							background-image: url("../img/icon__login--white.svg");
						}
					}
				}

				&:hover {
					background-image: url("../img/icon__login--blue.svg");
					@at-root {
						.bg-color__blue &,
						.bg-color__green &,
						.bg-color__orange &,
						.bg-color__pink &,
						.bg-color__red &,
						.home & {
							@include breakpoint(tablet) {
								background-image: url("../img/icon__login--white.svg");
							}
						}

						.menu-color--white & {
							@include breakpoint(tablet) {
								background-image: url("../img/icon__login--black.svg");
							}
						}
					}
				}
			}
		}

		&--icon-search {
			a {
				background-image: url("../img/icon__magnifier--black.svg");
				background-position: right center;
				background-size: contain;
				background-repeat: no-repeat;
				padding-right: 28px;
				@at-root {
					.menu-color--white & {
						@include breakpoint(tablet) {
							background-image: url("../img/icon__magnifier--white.svg");
						}
					}
				}

				&:hover {
					background-image: url("../img/icon__magnifier--blue.svg");
					@at-root {
						.bg-color__blue &,
						.bg-color__green &,
						.bg-color__orange &,
						.bg-color__pink &,
						.bg-color__red &,
						.home & {
							@include breakpoint(tablet) {
								background-image: url("../img/icon__magnifier--white.svg");
							}
						}

						.menu-color--white & {
							@include breakpoint(tablet) {
								background-image: url("../img/icon__magnifier--black.svg");
							}
						}
					}
				}
			}
		}

		&--active {
			a:link,
			a:visited {
				color: $color__white;
			}
			@at-root {
				.bg-color__white & {
					a:link,
					a:visited {
						color: $color__blue;
					}
				}
			}

			&.top-menu__item--icon-contact {
				a {
					background-image: url("../img/icon__contact--white.svg");
				}
				@at-root {
					.bg-color__white & {
						a {
							background-image: url("../img/icon__contact--blue.svg");
						}
					}
				}
			}

			&.top-menu__item--icon-login {
				a {
					background-image: url("../img/icon-login--white.svg");
				}
				@at-root {
					.bg-color__white & {
						a {
							background-image: url("../img/icon__login--blue.svg");
						}
					}
				}
			}

			&.top-menu__item--icon-search {
				a {
					background-image: url("../img/icon__magnifier--white.svg");
				}
				@at-root {
					.bg-color__white & {
						a {
							background-image: url("../img/icon__magnifier--blue.svg");
						}
					}
				}
			}
		}
	}
}

#cludo-search-form {
	display: none !important;
}
