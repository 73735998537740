$font__main: 'Open Sans', sans-serif;
$font__weight--light: 300;
$font__weight--light-italic: 300i;
$font__weight--regular: 400;
$font__weight--regular-italic: 400i;
$font__weight--medium: 600;
$font__weight--bold: 700;
$font__weight--bold-italic: 700i;
$font__weight--extra-bold: 800;
$font__size: 16;
$line-height: 28;
$line-height--calculated: $line-height / $font__size * 1rem;
