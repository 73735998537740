.shortcuts {
	margin-bottom: 48px;
	@include breakpoint(tablet) {
		text-align   : center;
		margin-bottom: 112px;
	}

	&__headline {
		@include font-size(12, 14);
		font-weight   : inherit;
		margin-bottom : 0.5rem;
		margin-top    : 1rem;
		text-transform: uppercase;
		border-top    : 1px solid $color__grey--light;
		padding-top   : 1rem;
		@include breakpoint(tablet) {
			border       : none;
			display      : inline;
			@include font-size(18,24);
			margin-bottom: 0;
			margin-top   : 0;
			margin-right : 20px;
		}
	}

	&__list {
		display: inline;
		padding: 0;
		margin : 0;
	}

	&__item {
		list-style: none;
		@include breakpoint(tablet) {
			display     : inline;
			margin-right: 24px;
		}
		@include breakpoint(desktop) {
			display: inline;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		@include font-size(18,24);
		font-weight        : $font__weight--bold;
		color              : $color__red;
		text-decoration    : none;
		background-image   : url("../img/arrow--red.svg");
		background-position: right center;
		background-repeat  : no-repeat;
		background-size    : 14px 12px;
		padding-top        : 6px;
		padding-bottom     : 6px;
		display            : block;
		@include breakpoint(tablet) {
			display       : inherit;
			padding-top   : 0;
			padding-bottom: 0;
			padding-right : 24px;
		}
		@include breakpoint(desktop) {
			display       : inherit;
			padding-top   : 0;
			padding-bottom: 0;
			padding-right : 24px;
			@include font-size(24,24);
		}

		&:link,
		&:visited {
			color: $color__red;
		}

		&:hover {
			color           : $color__blue;
			background-image: url("../img/arrow--blue.svg");
		}
	}
}
