.card {
	width: 100%;

	&--has-background {
		padding: 20px;
		@include breakpoint(tablet) {
			padding: 40px;
		}
	}

	&--text-with-link {
		margin-bottom: 40px;
		@include breakpoint(tablet) {
			margin-bottom: 0;
		}
	}

	&__headline {
		@include font-size(18,24);
		margin-bottom: 0.5em;
		@include breakpoint(tablet) {
			 @include font-size(24,32);
		}
		font-weight: $font__weight--medium;

		a {
			text-decoration: none;
		}
	}

	&__content {
		@include font-size(14, 24);
		font-weight: $font__weight--regular;
		@include breakpoint(tablet) {
			 @include font-size(16, 24);
		}

		h3 {
			@extend .card__headline;
		}
	}

	&__link {}

	&__preheader {
		@include font-size(10,24);
		font-weight: $font__weight--bold;
		text-transform: uppercase;
		margin: 0;
		@include breakpoint(tablet) {
			 @include font-size(12,24);
		}
	}
}
