.newsletter-signup {
	margin-bottom: 40px;

	.mc-field-group {
		display: flex;
		display: -webkit-flex;
		-webkit-flex-wrap: wrap;
	}

	label {
		display: none;
	}

	input {
		width: 75%;
		border: 2px solid $color__blue;
		background: $color__grey--footer;
		padding: 10px 20px;
		color: $color__white;

		&:focus {
			outline: none;
			border: 2px solid $color__red;
		}
	}

	button {
		width: 25%;
		background-color: $color__blue;
		color: $color__white;
		border: none;
		padding: 0;
		cursor: pointer;
		text-transform: uppercase;
		font-weight: $font__weight--bold;

		&:hover {
			background-color: $color__red;
		}
	}
}
