/*
 * 	Default theme - Owl Carousel CSS File
 */
$color-base: $color__grey !default;
$color-white: $color__white;
$color-gray: $color__grey--light !default; //nav
$nav-color: $color-white !default;
$nav-color-hover: $color-white !default;
$nav-font-size: 14px !default;
$nav-rounded: 3px !default;
$nav-margin: 5px !default;
$nav-padding: 4px 7px !default;
$nav-background: $color-gray !default;
$nav-background-hover: $color-base !default;
$nav-disabled-opacity: 0.5 !default; //dots
$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-rounded: 30px !default;
$dot-margin: 5px 7px !default;
$dot-background: $color-gray !default;
$dot-background-active: $color-base !default;
@import 'theme';
