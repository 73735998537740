/* Background colors */
.single-raad-og-vejledning {
	&.page-depth--0 {
		.content__header,
		.header {
			background-color: $color__blue;

			&.bg-color__white {
				background-color: $color__white;
			}
		}

		.content__header {
			&.article-page {
				background-color: $color__white;

				h1 {
					color: $color__blue;
				}
			}
		}

		h1 {
			color: $color__white;
		}
	}

	&.page-depth--1 {
		.content__header,
		.header {
			background-color: $color__white;

			&.subject-page {
				background-color: $color__blue;

				h1 {
					color: $color__white;
				}
			}

			h1 {
				color: $color__blue;
			}
		}
	}

	&.page-depth--2 {
		.header {
			background-color: $color__white;
		}
	}
}

.raad-og-vejledning {
	&__header {
		&--link-page {
			@include breakpoint(tablet) {
				padding-top: 64px;
			}
		}

		&-row {
			position: relative;
			z-index: 1;
			box-sizing: border-box;
			display: flex;
			flex: 0 1 auto;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 0 -72px;
			@include breakpoint(tablet) {
				margin: 0 -32px;
			}
			@include breakpoint(desktop) {
				margin: 0 -72px;
			}
		}

		&-col {
			flex: 0 0 auto;
			padding: 0 72px;
			box-sizing: border-box;
			@include breakpoint(tablet) {
				padding: 0 32px;
			}
			@include breakpoint(desktop) {
				padding: 0 72px;
			}

			&--left {
				width: 100%;
				@include breakpoint(tablet) {
					width: 50%;

					&.no-fact-box {
						width: 100%;
					}
				}
				@include breakpoint(desktop) {
					width: 37.5%;

					&.no-fact-box {
						width: 62.5%;
					}
				}
			}

			&--right {
				width: 100%;
				@include breakpoint(tablet) {
					width: 50%;
				}
				@include breakpoint(desktop) {
					width: 62.5%;
				}
			}
		}
	}
}
