.related-publications {
	&__headline {
		margin-bottom: 40px;
		text-transform: uppercase;
	}

	&__list {
		padding: 0;
		margin: 0;
		@at-root .related-publications--small & {
			margin-top: 10px;
		}
	}

	&__type {
		margin: 0;
		@include font-size(14,24);
		text-transform: uppercase;
		@at-root .related-publications--small & {
			 @include font-size(12,24);
		}
	}

	&__item {
		list-style: none;
		display: flex;
		align-items: stretch;
		margin-bottom: 48px;
		@at-root .related-publications--small & {
			margin-bottom: 8px;
		}
	}

	&__image {
		max-width: 200px;
		margin: 0;
		@at-root aside .related-publications--small & {
			max-width: 80px;
		}
	}

	&__description {
		@include breakpoint(tablet) {
			 @at-root .related-publications--small &{
				padding-left: 16px;
			}
		}
	}

	&__title {
		@include font-size(28,34);
		font-weight: $font__weight--regular;
		margin-bottom: 8px;
		@at-root .related-publications--small & {
			 @include font-size(16,24);
		}

		a {
			text-decoration: none;
			color: $color__blue;

			&:hover {
				color: $color__red;
			}
		}
	}

	&__date {
		@include font-size(16,24);
		font-weight: $font__weight--regular;
		@at-root .related-publications--small & {
			 @include font-size(12,24);
		}

		&:first-letter {
			text-transform: uppercase;
		}
	}

	&__links {
		padding: 0;
		margin: 0;

		&-item {
			list-style: none;
		}

		&-link {
			color: $color__red;
			text-decoration: none;
			font-weight: $font__weight--medium;

			&:after {
				content: " ";
				width: 14px;
				height: 12px;
				margin-left: 10px;
				display: inline-block;
				background-image: url("../img/arrow--red.svg");
				background-position: center;
				background-repeat: no-repeat;
				transition: all 220ms ease-in-out;
			}

			&:active,
			&:link,
			&:visited {
				color: $color__red;
			}

			&:hover {
				color: $color__blue;

				&:after {
					background-image: url("../img/arrow--blue.svg");
				}
			}
		}
	}
}
