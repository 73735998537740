.news-list {
  padding-bottom: 40px;

  &__headline {
    @include font-size(20, 24);
    margin-bottom: 8px;
    font-weight: $font__weight--medium;
    margin-top: 0;
    @include breakpoint(tablet) {
      @include font-size(28, 34);
    }

    a {
      color: $color__red;
      text-decoration: none;
      transition: all 220ms ease-in-out;

      &:hover {
        color: $color__text;
      }
      @at-root .news-list--black & {
        color: $color__text;

        &:hover {
          color: $color__white;
        }
      }
    }
  }

  &__lead {
    margin: 0;
  }

  &__date {
    @include font-size(12, 24);
    font-weight: $font__weight--bold;
    margin-bottom: 1em;
  }

  &__item {
    position: relative;
    margin-bottom: 40px;
    display: block;
  }

  &__image {
    width: 100%;
    height: auto;
    margin-top: 0;
    @include breakpoint(tablet) {
      margin-top: 40px;
    }
  }
}
