a {
  transition: all 220ms ease-in-out;
  /* unvisited link */
  &:link {
    color: inherit;
  }
  /* visited link */
  &:visited {
    color: inherit;
  }
  /* mouse over link */
  &:hover {
    color: inherit;
  }
  /* selected link */
  &:active {
    color: inherit;
  }
}

.cta {
  transition: all 220ms ease-in-out;
  background-color: $color__blue;
  height: 48px;
  display: inline-block;
  position: relative;
  color: $color__white;
  border-radius: 24px;
  padding: 0 32px;
  font-weight: $font__weight--medium;
  text-transform: uppercase;
  @include font-size(14, 48);
  cursor: pointer;

  &:active,
  &:hover,
  &:link,
  &:visited {
    color: $color__white;
    text-decoration: none;
  }
  @at-root .single-raad-og-vejledning .content__header.subject-page & {
    &:active,
    &:link,
    &:visited {
      background-color: $color__red;
      color: $color__white;
    }

    &:hover {
      color: $color__red;
      background-color: $color__white;
      text-decoration: none;
    }
  }

  &--arrow {
    width: 48px;
    display: inline-block;
    position: relative;
    text-indent: -9999px;
    color: $color__white;
    border-radius: 50%;
    padding: 0;

    &:after {
      content: "→";
      color: $color__white;
      text-indent: 0;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    line-height: 48px;
  }

  &:hover {
    background-color: $color__red;
    color: $color__blue;

    &:after {
      color: $color__blue;
    }
  }

  &--red {
    background-color: $color__red;
    color: $color__white;

    &:hover {
      background-color: $color__white;
      color: $color__red;

      &:after {
        color: $color__red;
      }
    }
  }

  &.disabled {
    background: $color__grey--light;
    color: darken($color__grey--light, 20%);
  }
}

.btn {
  &--link {
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: $color__red;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
