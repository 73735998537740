.publication-list {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	margin: 0 -1rem;
	//padding-bottom: 80px;
	&__item {
		width: 50%;
		padding: 0 1rem;
		margin-bottom: 40px;
		@include breakpoint(tablet) {
			width: 20%;
		}
	}
}

.publication {
	&__link {
		text-decoration: none;
	}

	&__image {
		width: 50%;
		margin: 2rem 1rem;
		height: auto;
		box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.25);
		transition: all 220ms ease-in-out;
		display: block;
		@include breakpoint(tablet) {
			width: 100%;
			margin: 0 0 0 -10px;
		}
		.accordion & {
			@include breakpoint(tablet) {
				margin-left: -10px;
			}
		}
	}

	&__title {
		text-decoration: none;
		@include font-size(16, 24);
		transition: all 220ms ease-in-out;

		a {
			text-decoration: none;

			&:hover {}
		}
	}

	&__row {
	    margin: 20px 20px 20px 0;
	}

	&__container {
		padding: 20px;
	    border: 1px solid $color__grey--light;
	}

	&__read-online {
		margin: 0;
	}
}

.publication-element {
	padding: 20px;
	border: 1px solid $color__grey--light;
}
