.findtr {

    &--filters {
        @include breakpoint(desktop) {
            width: 33.3333%;
        }

        & .facetwp-facet {
            margin-bottom: 1rem;

            & .facetwp-dropdown {
                width: 100%;
                border: 1px solid lightgrey;
            }
        }

        & .btn--reset {
            float: right;
            background: none;
            border: none;
            cursor: pointer;
            text-decoration: underline;
            margin-top: -1rem;
            margin-bottom: 2rem;
            color: $color__red;

            &:hover {
                color: black;
            }

            &:focus {
                outline: none;
            }
        }
    }

    & .tr-list {
        clear: both;

        & .people__item {
            @include breakpoint(tablet) {
                width: 33%;
            }
            margin: 0.167%;
            padding: 1rem;

            &:nth-of-type(odd) {
                background: $color__blue;
                & a {
                    color: white;

                    &:hover {
                        color: black;
                    }
                }
            }

            &:nth-of-type(even) {
                background: $color__red;
                color: white;

                & a {
                    color: black;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }

}
