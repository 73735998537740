.snapengage {
  margin-bottom: 2rem;
  &__opening-hours {
    background-color: $color__grey--lighter;
    padding: 1rem;
  }

  #snapengage-status {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: $color__grey--light;
      margin-right: 0.5rem;
    }

    &.open {
      &::before {
        background-color: $color__green;
      }
    }

    &.closed {
      &::before {
        background-color: $color__red;
      }
    }
  }

  &-floating-btn {
    padding: 1rem;
    background-color: $color__orange;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: 24px;
    color: $color__white;
    font-size: 14px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    transition: all 220ms ease-in-out;
    transform: translateY(calc(100% + 2rem));
    z-index: 10;
    border: 1px solid white;
    text-decoration: none;

    &.in {
      transform: translateY(0);
    }

    &:after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: 18px;
      bottom: -8px;
      border: 12px solid;
      border-color: $color__orange $color__orange transparent transparent;
    }

    &:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: 16px;
      bottom: -12px;
      border: 6px solid;
      border-color: $color__white $color__white transparent transparent;
    }
  }
}
