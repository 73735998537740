.footer {
    &__top {
        display: inline-block;
        width: 100%;
        padding: 30px 0 60px;
    }

    &__bottom {
        display: inline-block;
        width: 100%;
        padding: 30px 0 20px;
    }

    &__headline {
        @include font-size(12, 28);
        text-transform: uppercase;
        color: rgba($color__white, 0.5);
        font-weight: $font__weight--bold;
        margin-bottom: 18px;
    }

    &__about-us {
        @include font-size(22,30);
        font-weight: $font__weight--light-italic;
        font-style: italic;
        width: 100%;
        @include breakpoint(tablet) {
             @include font-size(28,38);
        }
        @include breakpoint(desktop) {}
    }

    &__align {
        &-right {
            @include breakpoint(tablet) {
                text-align: right;
            }
        }
    }

    &__ok-logo {
        @include breakpoint(tablet) {
            display: inline-block;
        }
    }
}
