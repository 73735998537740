.campaign-banner {
    padding: 1rem;
    position: relative;
    margin-bottom: 40px;
    cursor: pointer;
    @include breakpoint(tablet) {
        padding: 2rem;
        margin: 0;
    }

    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        z-index: -1;
    }

    &:hover {
        opacity: 0.9;
    }

    &.bg-color__blue {
        &:before {
            background-color: rgba($color__blue, 0.75);
        }
    }

    &.bg-color__red {
        &:before {
            background-color: rgba($color__red, 0.75);
        }
    }

    &.bg-color__green {
        &:before {
            background-color: rgba($color__green, 0.75);
        }
    }

    &.bg-color__orange {
        &:before {
            background-color: rgba($color__orange, 0.75);
        }
    }

    &.bg-color__pink {
        &:before {
            background-color: rgba($color__pink, 0.75);
        }
    }

    &__content {
        //position: relative;
        z-index: 1;
        @include breakpoint(tablet) {
            display: flex;
            align-items: center;
            margin: 0;
        }
    }

    &__text {
        width: 100%;
        margin-bottom: 1rem;
		z-index: 1;
        position: relative;
        @include breakpoint(tablet) {
            width: 62.5%;
            margin: 0;
        }
    }

    &__subheader {
        text-transform: uppercase;
        @at-root {
            .campaign-banner.bg-color__blue &,
            .campaign-banner.bg-color__green & {
                color: $color__red;
            }

            .campaign-banner.bg-color__red & {
                color: $color__text;
            }
        }
        @include font-size(12, 24);
        margin: 0;
        font-weight: $font__weight--medium;
    }

    &__headline {
        @include font-size(18, 24);
        margin: 0;
        font-weight: $font__weight--medium;
        @include breakpoint(tablet) {
            @include font-size(28, 32);
        }
        @at-root {
            .campaign-banner.bg-color__blue &,
            .campaign-banner.bg-color__green &,
            .campaign-banner.bg-color__orange &,
            .campaign-banner.bg-color__pink &,
            .campaign-banner.bg-color__red & {
                color: $color__white;
            }
        }
    }

    &__button {
        z-index: 1;
        width: 100%;
        @include breakpoint(tablet) {
            width: 37.5%;
            text-align: right;
        }

        .cta {
			position: static;
            @at-root {
                .campaign-banner.bg-color__blue &,
                .campaign-banner.bg-color__green & {
                    background-color: $color__red;
                    color: $color__white;

                    &:hover {
                        background-color: $color__white;
                        color: $color__red;

                        &:after {
                            color: $color__red;
                        }
                    }
                }
            }


			&:before {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				pointer-events: auto;
				content: '';
				background-color: transparent;
			}

            
        }
    }

	&__cta {
		display: inline-block;
	}



    &__image {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        @include breakpoint(tablet) {
            width: 37.5%;
        }
        background-position: right center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: -1px;
            bottom: 0;
            width: 100%;
            @include breakpoint(tablet) {
                width: 50%;
            }
            @at-root {
                .campaign-banner.bg-color__blue & {
                    background: linear-gradient(
                        to right,
                        rgba($color__blue, 1) 0%,
                        rgba($color__blue, 0) 200%
                    );
                    @include breakpoint(tablet) {
                        background: linear-gradient(
                            to right,
                            rgba($color__blue, 1) 0%,
                            rgba($color__blue, 0) 100%
                        );
                    }
                }

                .campaign-banner.bg-color__red & {
                    background: linear-gradient(
                        to right,
                        rgba($color__red, 1) 0%,
                        rgba($color__red, 0) 100%
                    );
                }

                .campaign-banner.bg-color__orange & {
                    background: linear-gradient(
                        to right,
                        rgba($color__orange, 1) 0%,
                        rgba($color__orange, 0) 100%
                    );
                }

                .campaign-banner.bg-color__green & {
                    background: linear-gradient(
                        to right,
                        rgba($color__green, 1) 0%,
                        rgba($color__green, 0) 100%
                    );
                }

                .campaign-banner.bg-color__pink & {
                    background: linear-gradient(
                        to right,
                        rgba($color__pink, 1) 0%,
                        rgba($color__pink, 0) 100%
                    );
                }
            }
        }
    }
}
