body {
	font-family: $font__main;
	color: $color__text;
	@include font-size(14, 24.5);
	font-weight: $font__weight--light;
	@include breakpoint(tablet) {
		 @include font-size(16, 28);
	}
}

p {
	margin: 0 0 24.5px;
	@include breakpoint(tablet) {
		margin: 0 0 $line-height--calculated;
	}
}

.manchet {
	@include font-size(18, 26);
	font-weight: $font__weight--light;
	@include breakpoint(tablet) {
		 @include font-size(28, 38);
		max-width: 740px;
	}
}

.font__weight--normal {
	font-weight: $font__weight--regular;
}