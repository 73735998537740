.data {
	margin-bottom: 48px;
	@include breakpoint(tablet) {
		margin-bottom: 72px;
	}

	&__headline {}

	&__list {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
	}

	&__item {
		text-decoration: none;
		width: 100%;
		list-style: none;
		text-align: center;
		@include font-size(18,22);
		position: relative;
		padding: 30px 30px 50px;

		&:after {
			content: " ";
			width: 112px;
			height: 1px;
			background-color: $color__grey--light;
			position: absolute;
			top: 100%;
			right: 50%;
			transform: translateX(50%);
		}

		&:last-child {
			padding-bottom: 30px;

			&:after {
				display: none;
			}
		}
		@include breakpoint(tablet) {
			width: 25%;
			padding: 16px;
			@include font-size(16,20);

			&:after {
				content: " ";
				width: 1px;
				height: 70px;
				background-color: $color__grey--light;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%) rotate(25deg);
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
		@include breakpoint(desktop) {
			 @include font-size(18,22);
			padding: 32px;
		}
	}

	&__link {
		text-decoration: none;
		position: relative;
		display: inline-block;

		&:after {
			content: " ";
			width: 14px;
			height: 12px;
			background-image: url("../img/arrow--green.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 14px 12px;
			display: inline-block;
			position: absolute;
			bottom: -29px;
			left: 50%;
			transform: translateX(-50%);
			transition: all 220ms ease-in-out;
		}

		&:hover {
			&:after {
				background-image: url("../img/arrow--red.svg");
			}
		}
	}

	&__number {
		color: $color__green;
		display: block;
		@include font-size(30,34);
		margin-bottom: 0.16em;
		@include breakpoint(tablet) {
			 @include font-size(40,40);
		}
		@include breakpoint(desktop) {
			 @include font-size(60,60);
		}
	}

	&__item {}
}
