.top-image {
	width : 100%;
	height: auto;

	&__container {
		margin-bottom: 40px;
	}

	&__caption-text {
		@include font-size(12, 18);
		color: $color__grey;
	}
}

.wp-caption-text {
	@include font-size(12, 18);
	color: $color__grey;
}

.content {
	.container {
		img {
			max-width: 100%;
			height   : auto;
			width    : auto;
		}
	}
}
