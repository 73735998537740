.block {
	display: inline-block;
	width: 100%;

	&--cta {
		margin: 0;
		padding-top: 20px;

		&.no-bottom-margin {
			margin-bottom: 0;
		}

		&.extra-bottom-margin {
			margin-bottom: 60px;
		}
		@include breakpoint(tablet) {
			margin: 0 0 48px;

			&.no-bottom-margin {
				margin-bottom: 0;
			}

			&.extra-bottom-margin {
				margin-bottom: 88px;
			}
		}

		&.angled-bottom {
			margin-bottom: 136px;

			&:after {
				height: 140%;
			}
		}
	}
}
