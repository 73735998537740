.featured-articles {
	margin-bottom: 48px;
	@include breakpoint(tablet) {
		margin-bottom: 80px;
	}

	&__row {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin: 0 -1rem;
		@include breakpoint(tablet) {
			margin: 0 -2rem;
		}
	}

	&__col {
		width: 100%;
		padding: 0 1rem;
		@include breakpoint(tablet) {
			width: 50%;
			padding: 0 2rem;
		}

		&--full-height {
			@include breakpoint(tablet) {
				display: flex;
				position: relative;
			}
		}
	}

	&__item {
		margin-bottom: 1rem;
		display: flex;
		@include breakpoint(tablet) {
			margin-bottom: 50px;
		}

		&--1-3,
		&--1-8,
		&--8-8 {
			display: inline-block;
			width: 100%;
			margin-bottom: 1rem;
			position: relative;
			@include breakpoint(tablet) {
				margin-bottom: 80px;
				position: absolute;
				top: 0;
				left: 2rem;
				right: 2rem;
				bottom: 0;
				width: auto;
			}
		}

		&--has-image {
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	&__card {
		&--1-3,
		&--1-8,
		&--8-8 {
			@include breakpoint(tablet) {
				position: absolute;
				bottom: -32px;
				left: 24px;
				right: 24px;
				width: auto;
			}
		}

		&--transparent {
			background-color: rgba($color__green, 0.8);
		}
	}
}
