.branding {
	display: inline-block;
	padding: 0.5rem 0;
	@include breakpoint(tablet) {
		padding: 20px 0 40px;
		//float: left;
	}
	@include breakpoint(desktop) {
		padding: 52px 0;
	}

	&__logo {
		width: 170px;
		height: 58px;
		@include breakpoint(tablet) {
			width: 258px;
			height: 88px;
		}
	}
}

.header {
	display: inline-block;
	width: 100%;
	position: relative;
	&.home-test {
		position: absolute;
		z-index: 2;
		top: 0;
	}
	&--blue {
		background-color: $color__blue;
	}

	.navigation {
		//background: $color__white;
		position: relative;
		@include breakpoint(tablet) {
			background: none;
		}
	}
}

body.home {
	.header {
		position: absolute;
		z-index: 2;
		top: 0;
	}
}
