.social-links {
  margin-top: 2rem;

  &__list {
    padding: 0;
  }

  &__item {
    list-style  : none;
    display     : inline;
    margin-right: 6px;
  }

  &__link {
    color           : $color__white;
    background-color: $color__blue;
    width           : 46px;
    height          : 46px;
    display         : inline-block;
    text-indent     : -9999px;
    border-radius   : 50%;
    transition      : all 220ms ease-in-out;

    &:hover {
      background-color: $color__red;
    }

    &--facebook {
      background-image   : url("../img/icon__facebook--red.svg");
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : 100%;

      &:hover {
        background-image: url("../img/icon__facebook--blue.svg");
      }
    }

    &--instagram {
      background-image   : url("../img/icon__instagram--red.svg");
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : 100%;

      &:hover {
        background-image: url("../img/icon__instagram--blue.svg");
      }
    }

    &--twitter {
      background-image   : url("../img/icon__twitter--red.svg");
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : 100%;

      &:hover {
        background-image: url("../img/icon__twitter--blue.svg");
      }
    }
  }
}
