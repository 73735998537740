.info-box {
    color: $color__red;
    font-weight: $font__weight--bold;
    min-height: 48px;

    &__content {
        padding-left: 46px;
        @include breakpoint(tablet) {
            padding-left: 62px;
        }
    }

    &:before {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid $color__blue;
        content: "i";
        display: inline-block;
        float: left;
        @include font-size(20);
        line-height: 32px;
        text-align: center;
        font-weight: $font__weight--bold-italic;
        font-style: italic;
        @include breakpoint(tablet) {
            width: 48px;
            height: 48px;
            @include font-size(24);
            line-height: 42px;
        }
    }
}
