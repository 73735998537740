.people {
	@include font-size(16,20);

	&__headline {
		margin-bottom: 36px;
		text-transform: normal;
		margin: 40px 0 20px;
		font-weight: $font__weight--regular;
	}

	&__list {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin: 0 -16px;
	}

	&__item {
		width: 100%;
		padding: 0 16px;
		margin-bottom: 40px;
		@include breakpoint(tablet) {
			width: 33.3333%;
		}
		@include breakpoint(desktop) {
			width: 25%;
		}
	}

	&__name {
		font-size: inherit;
		text-transform: uppercase;
		font-weight: $font__weight--medium;
	}

	&__email {
		overflow: hidden;
		text-overflow: ellipsis;

		a {
			color: $color__red;
		}
	}

	&__image {
		width: 100%;
		height: auto;
		margin-bottom: 28px;
	}

	&__more-info {
		padding-top: 1rem;

		&-content {
			padding-top: 1rem;
			max-height: 0;
			overflow: hidden;
			transition: all 220ms ease-in-out;

			&.is-open {
				max-height: 400px;
			}
		}
	}
}
