.angled-bottom {
	@include angle(after, false, 3.3deg);
	margin-bottom: 10%;

		@include breakpoint(desktop) {
		margin-bottom: 190px;
	}
}

.angled-top {
	@include angle(before, false, 3.3deg);
	//margin-top: 12%;
	padding-top: 12%;
	overflow: hidden;

	&:before {
		height: 10vw;
		background-color: $color__white;
	}
}
