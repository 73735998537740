.facet-filters {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	margin-bottom: 20px;

	&__filter {
		width: 100%;
		@include breakpoint(tablet) {
			width: 300px;
			padding-right: 20px;
		}

		select {
			width: 100%;
		}
	}

	&__label {
		margin: 0;
		font-weight: $font__weight--bold;
	}

	.facetwp-facet {
		margin-bottom: 20px;
		height: 24px;
	}
}
