.table,
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
  border: none;

  thead {
    th {
      border-left: none;
      border-right: none;
      border-top: none;
      text-align: left;
      // border-bottom: 2px solid $color__grey--light;
      padding: 10px;
      font-weight: $font__weight--regular;
      @include font-size(18, 24);
    }
  }

  tbody {
    td {
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid $color__grey--light;
      @include breakpoint(tbalet) {
        padding: 10px;
      }
    }
  }

  &__container {
    width: 100%;
    overflow-x: auto;
    @include breakpoint(tablet) {
      overflow: visible;
    }
  }
}

.table {
  thead {
    position: sticky;
    top: 0;
    background-color: #fff;

    th {
      box-shadow: inset 0 -2px 0 #d9d9d9;
    }
  }
  @include breakpoint(tablet) {
    width: 62.5%;
  }
  @at-root .main-body__content & {
    @include breakpoint(tablet) {
      width: 100%;
    }
  }
}
/*
.responsive-table {
	margin-bottom: 1em;
	border-top: 1px solid $color__grey--light;
	@include breakpoint(tablet) {
		border-top: none;
	}

	thead {
		position: absolute;
		clip: rect(1px 1px 1px 1px);
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0;
		border: 0;
		height: 1px;
		width: 1px;
		overflow: hidden;
		@include breakpoint(tablet) {
			position: inherit;
		}

		tr {
			@include breakpoint(tablet) {
				border-bottom: 2px solid $color__grey--light;
			}
		}

		th {
			text-align: center;
			@include breakpoint(tablet) {
				text-align: left;
				border-bottom: 2px solid $color__grey--light;
			}

			&:first-of-type {
				text-align: left;
			}
		}
	}
	tbody,
	td,
	th,
	tr {
		display: block;
		text-align: left;
		white-space: normal;
	}

	tbody {
		@include breakpoint(tablet) {
			display: table-row-group;
		}
	}

	tr {
		@include breakpoint(tablet) {
			display: table-row;
		}
	}

	td,
	th {
		vertical-align: middle;
		border: none !important;
		@include breakpoint(tablet) {
			display: table-cell;
			border-bottom: 1px solid $color__grey--light;
		}
	}

	caption {
		margin-bottom: 1em;
		font-size: 1em;
		font-weight: bold;
		text-align: center;
		display: none;
	}

	tfoot {
		font-style: italic;
	}

	tbody {
		tr {
			width: 100%;
			float: left;
			border-collapse: collapse;
			border-bottom: 1px solid $color__grey--light;
			margin-top: -1px;
			@include breakpoint(tablet) {
				float: none;
				display: table-row;
			}

			&.hidden-row {
				display: none;

				&.show {
					display: block;
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			&:nth-of-type(even) {}

			&:nth-child(2n+2) {}

			&.full-width {
				width: 100%;
				display: block;
				float: none;
				min-height: auto;

				&:nth-child(2n+2) {
					border-left: 1px solid $color__grey--light;
				}
			}

			td {
				&:first-child {
					padding-top: 10px;
					@include breakpoint(tablet) {
						padding: 10px;
					}
				}

				&:last-child {
					padding-bottom: 10px;
					@include breakpoint(tablet) {
						padding: 10px;
					}
				}
			}
		}

		th[scope="row"] {}

		td {
			text-align: left;
		}

		td[data-title]:before {
			content: attr(data-title);
			float: left;
		}
	}
}
*/
