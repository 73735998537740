.alignleft, .alignright {
    display: block;
    width: 100%;
    margin: 20px 0;   

    .wp-caption-text {
        text-align: center;
        font-style: italic;
        font-size: small;
    }

    @include breakpoint(desktop) {
        width: unset;
    }
}

.alignleft {
    @include breakpoint(desktop) {
        float: left;
        margin: 0 20px 20px 0;
    }

}

.alignright {
    @include breakpoint(desktop) {
        float: right;
        margin: 0 0 20px 20px;
    }
}