.page-template-template-landing-page-alt,
.page-template-template-landing-page {
  .breadcrumbs {
    display: none;
  }

  .headline-block {
    padding-top: 4.5em;
  }

  .mobile-menu-toggle__menubg,
  .mobile-menu-wrapper,
  .mobile-menu-toggle {
    display: none;
  }

  .quotes__item {
    color: $color__orange;
  }

  .footer__top {
    display: none;
  }

  h1.headline {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
  }

  .main-content {
    background-color: #fff5e3;
    padding: 0;
    margin-bottom: 1rem;

    @include breakpoint(tablet) {
      padding: 4rem 0;
      margin-bottom: 4rem;
    }

    h2 {
      color: $color__orange;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 1em;

      @include breakpoint(tablet) {
        font-size: 40px;
      }
    }
  }

  .main-body__content--landing-page-alt,
  .main-body__content--landing-page {
    margin: 0 auto;
  }
}

.page-template-template-landing-page-alt {
  .main-content {
    background-color: #C3E6E9;

    h2 {
      color: #000;
    }
  }
}

.landing-page-formular-alt,
.landing-page-formular {
  background-color: $color__orange;
  color: $color__white;
  padding: 1rem 1rem;
  margin-bottom: 2rem;

  @include breakpoint(tablet) {
    padding: 2rem 4rem;

    max-width: (460 / 16) * 1rem;
    margin: 0 auto 2rem;
  }

  h2,
  h3 {
    color: $color__white;
    font-size: 24px;
    margin: 20px 0;
    text-align: center;
  }

  .nf-field-label label {
    color: $color__white;
    font-weight: 400;
  }

  .ninja-forms-field {
    border: none;
    padding: 4px;
  }

  .submit-wrap {
    input {
      background-color: $color__blue;
      border: none;
      color: $color__white;
      font-weight: bold;
      @extend .cta;
    }
  }

  .checkbox-wrap {
    flex-wrap: wrap;

    .nf-field-description {
      width: 100%;
      margin-right: 0;
    }
  }

  .label-right .checkbox-wrap .nf-field-label {
    width: 70%;
  }
}

.page-template-template-landing-page-alt .landing-page-formular {
  background-color: #EA5053;

  .submit-wrap {
    input {
      background-color: #fff;
      color: #547f82;

      &:hover {
        background-color: $color__blue;
        color: #547f82;
      }

    }
  }
}

.page-template-template-landing-page-alt .quotes__item {
  color: #88cdd3;
}