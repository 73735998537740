.faggruppe-list {
	padding-bottom: 40px;

	&__headline {
		@include font-size(28,34);
		font-weight: $font__weight--medium;
		margin-bottom: 8px;
		margin-top: 0;

		a {
			color: $color__red;
			text-decoration: none;
			transition: all 220ms ease-in-out;

			&:hover {
				color: $color__black;
			}
		}
	}

	&__lead {
		margin: 0 0 2px;
		@include font-size(16,24);
	}

	&__date {
		@include font-size(12,24);
		font-weight: $font__weight--bold;
		margin-bottom: 1em;
	}

	&__item {
		position: relative;
		margin-bottom: 40px;
		display: inline-block;
	}

	&__image {
		width: 100%;
		height: auto;
		padding-bottom: 60%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: $color__blue;
		background-blend-mode: multiply;
		margin-top: 0;
		@include breakpoint(tablet) {
			margin-top: 40px;
		}
	}
}
