.breadcrumbs {
	display: none;
	@include breakpoint(tablet) {
		display: flex;
	}
	@include font-size(12, 24);
	color: rgba($color__grey, 0.8);

	span[property="itemListElement"] {
		span[property="name"] {
			color: rgba($color__grey, 0.8);
			display: inline-block;
			//max-width: 20%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0 10px;
			vertical-align: bottom;
		}

		&:first-child {
			span[property="name"] {
				margin-left: 0;
			}
		}

		&:last-child {
			span[property="name"] {
				display: inline-block;
				max-width: 100%;
				white-space: nowrap;
				overflow: visible;
			}
		}
	}
}
