.overlay-modal {
  align-items: center;
  display: none;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 3;

    &::after {
      content: "";
      background: $color__grey--dark;
      display: inline-block;
      height: 100%;
      opacity: 0.8;
      position: fixed;
      transition-delay: 500ms;
      transition: ease-out all 800ms;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: 2;
    }

  &.in {
    display: flex;


  }

  a {
    font-weight: bold;
  }


  &__inner {
    background: #fff;
    -webkit-transition: all 800ms;
    border: 0;
    border-radius: 5px;
    margin: 0;
    max-width: 95%;
    padding: 40px 25px;
    position: relative;
    text-align: justify;
    transition: all 800ms;
    z-index: 4;

    @include breakpoint(tablet) {
      max-width: 80%;
      padding: 40px 100px;
      text-align: justify;  
    }

    @at-root .overlay-modal.red & {
      background: #BBE2E3;
    @include breakpoint(tablet) {
      padding: 40px;
          }      
    }

    &.in {
      -moz-transition: all 0.8s;
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -webkit-transition: all 0.8s;
      -webkit-transform: scale(0.8);
      background: #f9f9f9;
      margin-top: -200%;
      opacity: 0;
      transition: all 0.8s;
      transform: rotate(2.75deg);
      transform: scale(0.8);
      }

    &__close-button {
      background: #ea5053;
      border: 3px solid #111;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      font-size: 30px;
      margin-top: -65px;
      padding: 5px 10px;
      position: absolute;
      right: 20px;
      transform: rotate(25deg);
    }

    &__text {
      margin: 25px 0;

      &__logo {
        float: left;
        width: 50%;
        height: 100px;
        img {
          max-width: 100%;      
          max-height: 100%;    
          float: left;            
        }
      }

      &__logo-2 {
        width: 50%;
        float: right;
        height: 100px;

        img {
          max-width: 100%;    
          max-height: 100%;   
          float: right; 
        }
      }

      &__heading {
        color: $color__blue;
        display: inline-block;
        width: 100%;
        font-size: 2.5rem;
        font-weight: 800;
        line-height: 4rem;
        text-align: left;

        @include breakpoint(tablet) {
          font-size: 3.5rem;        
        }

        @at-root .overlay-modal.red .overlay-modal__inner & {
          color: #ea5053;
          font-size: 2.6rem;
          text-transform: uppercase;
        }
      }
    }

    &__button {
      -webkit-transition: all 220ms ease-in-out;
      border-radius: 24px;
      background-color: $color__blue;
      color: $color__white;
      height: 48px;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 3rem;
      margin: 0 auto;      
      position: relative;
      padding: 0 32px;
      text-align: center;
      text-transform: uppercase;
      transition: all 220ms ease-in-out;
      width: 70%;

      @include breakpoint(tablet) {
        display: inline-block;
        width: inherit;
        
      }      
    }

    &__link {
      cursor: pointer;
      margin: 25px auto;
      text-decoration: underline;
      text-align: center;

        @include breakpoint(tablet) {
          bottom: 30px;
          float: right;
          right: 30px;
          margin: 0;
        }      
    }
    &__repeater {
      padding-top: 2rem;
      @include breakpoint(tablet) {
        padding-left: 5rem;

      }
      &::before {
        content: "";
        position: absolute;
        width: 9rem;
        height: 9rem;
        background: $color__blue;
        border-radius: 50%;
        z-index: -1;
        margin-top: -2rem;
      @include breakpoint(tablet) {
        margin-left: -5rem;

      }
      }
      &-heading {
        text-transform: uppercase;
        color: #ea5053;
        font-size: 25px;
        font-weight: bold;
      }
      &:nth-child(2n) {
      @include breakpoint(tablet) {
        margin-left: 2rem;
      }
    }

    }
  }

  &.in {
    // @at-root body {
    //     overflow: hidden;
    //   }
   

    &::after {
      content: "";
      background: $color__grey--dark;
      height: 100%;
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  a {
    text-decoration: none;
  }

  &.red {
        align-items: flex-start;
        display: none;
        height: auto;
        justify-content: center;
        position: relative;
        right: 0;
        width: 100%;
        z-index: 3;
        // margin-top: 5rem;
        margin-bottom: 5rem;

        &.in {
          display: flex;
        }
      
        // &.in {
        //   display: flex;
        // }
  
        &::after {
          content: "";
          background: $color__grey--lighter;
          display: inline-block;
          height: 100%;
          opacity: 1;
          position: fixed;
          transition-delay: 500ms;
          transition: ease-out all 800ms;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          z-index: 2;
        }

    h2 {
      margin-top: 1rem;
    }

    h3 {
      color: #ea5053;
      line-height: normal;
      margin-bottom: 1rem;
      @include breakpoint(tablet) {
      font-size: 40px;
      }
    }

 

    @at-root .has-modal-active {
      header, .content, footer {
        position: absolute;
        top: 0;
        z-index: -1;
        height: 0 !important;
        overflow: hidden;
      }
    }

    .overlay-modal__inner__close-button {
      transform: rotate(0);
      font-size: 1rem;
    }

   

    @at-root .overlay-modal__inner {
      text-align: left;
      box-shadow: 0 0px 7px 0px #888;

      &.in {
        -moz-transition: all 0.8s;
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -webkit-transition: all 0.8s;
        -webkit-transform: scale(0.8);
        background: #f9f9f9;
        margin-top: 0;
        opacity: 1;
        
      }
    }

    .overlay-modal--dont-show-again {
      font-size: 25px;
      font-weight: bold;
      display: block;
      width: 100%;
      text-align: center;
      clear: both;
      cursor: pointer;
    }

  }

  &__footer {
    // display: flex;
    color: #ea5053;
    &-text {

      @include breakpoint(tablet) {
      width: 35%;
      float: left;
      }
    }
    &-logo {
      @include breakpoint(tablet) {
      width: 35%;
      float: right;
      text-align: right;
        
      }
    }
  }

}