// Color classes

.color {
  &__red {
    color: $color__red;
  }

  &__yellow {
    color: $color__yellow;
  }

  &__pink {
    color: $color__pink;
  }

  &__green {
    color: $color__green;
  }

  &__blue {
    color: $color__blue;
  }

  &__orange {
    color: $color__orange;
  }

  &__white {
    color: $color__white;
  }

  &__black {
    color: $color__black;
  }

  &__grey {
    color: $color__grey;
  }

  &__grey--dark {
    color: $color__grey--dark;
  }

  &__grey--footer {
    color: $color__grey--footer;
  }
}
// Background color classes

.bg-color {
  &__red {
    background-color: $color__red;
  }

  &__yellow {
    background-color: $color__yellow;
  }

  &__pink {
    background-color: $color__pink;
  }

  &__green {
    background-color: $color__green;
  }

  &__blue {
    background-color: $color__blue;
  }

  &__orange {
    background-color: $color__orange;
  }

  &__white {
    background-color: transparent;
  }

  &__black {
    background-color: $color__black;
  }

  &__grey {
    background-color: $color__grey;
  }

  &__peach {
    background-color: $color__peach;
  }
  &__grey--new{
    background-color: $color__grey--new;
  }
  &__grey--dark {
    background-color: $color__grey--dark;
  }

  &__grey--footer {
    background-color: $color__grey--footer;
  }
}
