html {
  scroll-behavior: smooth;
}

.campaign-nav {
  display: none;
  @include breakpoint(tablet) {
    display: block;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li a {
        height: 20px;
        width: 20px;
        border: 1px solid $color__red;
        background-color: $color__white;
        border-radius: 50%;
        display: inline-block;
        position: relative;

        &:hover {
          .campaign-nav-title {
            opacity: 1;
          }
        }

        .campaign-nav-title {
          position: absolute;
          left: -160px;
          width: 150px;
          text-align: right;
          opacity: 0;
          transition: 0.3s;
          line-height: 1.2em;
        }
      }

      li a.current {
        background: $color__red;

        .campaign-nav-title {
          opacity: 1;
        }
      }
    }
  }
}

.campaign-section {
  width: 100%;

  .quotes__item,
  .quotes__name,
  .quotes cite {
    color: $color__white;
  }
}

.campaign-content {
  min-height: 500px;
  position: relative;
  @include breakpoint(tablet) {
    padding-top: 4rem;
  }

  &-minus {
    @include breakpoint(tablet) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.campaign-content-secondary {
  position: relative;
  min-height: 300px;
  width: 100%;
}

.campaign-section-text p:first-child {
  margin-bottom: 0 !important;
}

.campaign-content-box {
  padding: 1rem 3rem;
  @include breakpoint(tablet) {
    padding: 2em 11em 4em;
  }
}

.peach-bg {
  background-color: $color__peach;
}

.white-bg {
  background-color: $color__white;
}

.red-bg {
  background-color: $color__red;
}

.campaign-section-icon {
  display: none;
  @include breakpoint(tablet) {
    display: block;
    position: absolute;
    z-index: 2;
    width: 8em;
    height: 8em;
    padding: 1em 0;

    &.left {
      left: calc(10% - 64px);
    }

    &.right {
      right: calc(10% - 64px);
    }

    img {
      width: 50% !important;
      margin: 0 25%;
    }

    &-text {
      background-color: $color__red;
      color: $color__white;
      text-align: center;
      border-radius: 8px;
    }
  }
}

.campaign-image-fullwidth {
  background-size: cover;
  height: 100%;
  background-position: center center;
  width: 100%;
  height: 300px;
}

.lines {
  display: block;
  position: relative;
  height: 100%;
}

// Linjer
.l-vertical-1,
.l-vertical-2 {
  border-left: 2px solid $color__red;
  top: 0;
  bottom: 0;
  position: absolute;
  left: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.r-vertical-1,
.r-vertical-2 {
  border-left: 2px solid $color__red;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.l-r-l-1 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  left: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-l-2 {
  border-top: 2px solid $color__red;
  top: 40px;
  position: absolute;
  left: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-l-3 {
  border-left: 2px solid $color__red;
  top: 40px;
  position: absolute;
  right: 10%;
  height: calc(100% - 80px);

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-l-4 {
  border-top: 2px solid $color__red;
  bottom: 40px;
  position: absolute;
  right: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-l-5 {
  border-left: 2px solid $color__red;
  bottom: 0;
  position: absolute;
  left: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-l-6 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  left: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.r-l-r-1 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  right: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-r-2 {
  border-top: 2px solid $color__red;
  top: 40px;
  position: absolute;
  right: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-r-3 {
  border-left: 2px solid $color__red;
  top: 40px;
  position: absolute;
  left: 10%;
  height: calc(100% - 80px);

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-r-4 {
  border-top: 2px solid $color__red;
  bottom: 40px;
  position: absolute;
  left: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-r-5 {
  border-left: 2px solid $color__red;
  bottom: 0;
  position: absolute;
  right: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-r-6 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  right: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.l-r-1 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  left: 10%;
  height: calc(100% - 40px);

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-2 {
  border-top: 2px solid $color__red;
  bottom: 40px;
  position: absolute;
  left: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-3 {
  border-left: 2px solid $color__red;
  bottom: 0;
  position: absolute;
  right: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.l-r-4 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  right: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.r-l-1 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  right: 10%;
  height: calc(100% - 40px);

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-2 {
  border-top: 2px solid $color__red;
  bottom: 40px;
  position: absolute;
  right: 10%;
  width: 80%;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-3 {
  border-left: 2px solid $color__red;
  bottom: 0;
  position: absolute;
  left: 10%;
  height: 40px;

  @at-root .red-bg & {
    display: none;
  }
}
.r-l-4 {
  border-left: 2px solid $color__red;
  top: 0;
  position: absolute;
  left: 10%;
  height: 100%;

  @at-root .red-bg & {
    display: none;
  }
}

.imagemap-wrapper {
  position: relative;
}

#navigation {
  display: none;
  @include breakpoint(tablet) {
    margin: 0;
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 690 / 1120 * 100%;
    margin-top: -5rem;

    svg {
      //   max-width: 100%;
      //   height: auto;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.hoverbox {
  width: 200px;
  display: block;
  height: auto;
  background: rgba($color__peach, 1);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  color: $color__grey;
  font-size: 14px;
  line-height: 20px;
  padding: 1rem;
  border-radius: 4px;
  z-index: 10;
}
